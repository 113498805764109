import "@blueprintjs/table/lib/css/table.css";
// import "normalize.css"; // This causes three dots to appear under the star that indicate the required field for lightning design components.
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Table2, Column, Cell, ColumnHeaderCell } from "@blueprintjs/table";
import {
  HotkeysProvider,
  Menu,
  MenuItem,
  Classes,
  MenuDivider,
  Icon,
} from "@blueprintjs/core";
import { useEffect } from "react";

import CustomTooltip from "../../ui/CustomTooltip";

const bgColor = "#f3f3f3";
const DataTable = ({
  columns,
  data,
  onheaderaction,
  isEditable,
  handleTableResize,
}) => {
  useEffect(() => {
    const tableDivContainer = document.querySelector(
      ".bp5-table-quadrant-scroll-container"
    );
    const tableTop = document.querySelector(
      ".bp5-table-quadrant.bp5-table-quadrant-top"
    );
    const tableTopContainer = document.querySelector(
      ".bp5-table-column-header-tr"
    );

    if (tableTop) {
      tableTop.style.backgroundColor = bgColor;
    }
    if (tableDivContainer) {
      tableDivContainer.style.backgroundColor = bgColor;
    }
    if (tableTopContainer) {
      tableTopContainer.style.backgroundColor = bgColor;
    }
  }, []);

  const convertArrayToObjects = (dataArray, columnLabels) => {
    return dataArray.map((item) => {
      const convertedItem = { id: item[0]?.toString() };

      columnLabels.forEach((column, index) => {
        convertedItem[column.name] = item[index + 1]?.toString();
      });

      return convertedItem;
    });
  };

  const records = convertArrayToObjects(data, columns);

  const cellRenderer = (rowIndex, colLabel) => {
    const cell = records[rowIndex][colLabel];

    return (
      <Cell style={{ display: "flex", alignItems: "center" }}>
        <CustomTooltip
          content={
            <div
              style={{
                fontFamily: "Arial, sans-serif",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              {cell}
            </div>
          }
          title=""
        />
      </Cell>
    );
  };

  const onSortAction = (colLabel, sortDirection) => {
    console.log("onSortAction", colLabel, sortDirection);
  };

  const renderMenu = (column) => {
    if (!isEditable) {
      return;
    }

    return (
      <Menu className={Classes.ELEVATION_1}>
        {column.actions.map((item) => (
          <MenuItem
            icon="edit"
            key={item.label}
            text={item.label}
            onClick={() => onheaderaction(item)}
          />
        ))}
        {/* <MenuDivider />
        <MenuItem
          icon="sort-asc"
          text="Sort Asc"
          onClick={() => onSortAction(column.label, "asc")}
        />
        <MenuItem
          icon="sort-desc"
          text="Sort Desc"
          onClick={() => onSortAction(column.label, "desc")}
        /> */}
      </Menu>
    );
  };

  const handleColumnWidthChanged = (index, width) => {
    const widths = columns.map((col, colIndex) => {
      if (colIndex === index) {
        return width;
      } else {
        return col.columnWidth || null;
      }
    });

    handleTableResize(widths);
  };

  const rowHeaderCellRenderer = (index) => {
    return (
      <Cell
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "Arial, sans-serif",
          fontSize: 14,
          fontWeight: 400,
          justifyContent: "end",
          backgroundColor: bgColor,
        }}
      >
        {index + 1}
      </Cell>
    );
  };

  const nameRenderer = (column) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        title={column.label}
      >
        <span
          className="slds-tree__item-label slds-truncate"
          style={{
            fontWeight: "bold",
          }}
        >
          {column.label}
        </span>
        <Icon icon="chevron-down" text="chevron-down" />
      </div>
    );
  };

  const renderColumnHeader = (col) => {
    return (
      <ColumnHeaderCell
        name={col.name}
        nameRenderer={() => nameRenderer(col)}
        menuRenderer={() => renderMenu(col)}
        style={{
          fontWeight: "bold",
          backgroundColor: bgColor,
        }}
      />
    );
  };

  return (
    <HotkeysProvider>
      <Table2
        numRows={data.length}
        enableColumnResizing={isEditable}
        columnWidths={columns.map(
          (col) => col.columnWidth || col.initialWidth || 150
        )}
        onColumnWidthChanged={(index, width) =>
          handleColumnWidthChanged(index, width)
        }
        // cellRendererDependencies={columns.map(
        //   (column, index) => records[index][column.name]
        // )}
        rowHeaderCellRenderer={(index) => rowHeaderCellRenderer(index)}
      >
        {columns.map((col) => {
          return (
            <Column
              key={col.name}
              name={col.name}
              cellRenderer={(rowIndex) => cellRenderer(rowIndex, col.name)}
              columnHeaderCellRenderer={() => renderColumnHeader(col)}
            />
          );
        })}
      </Table2>
    </HotkeysProvider>
  );
};

export default DataTable;
